import React from "react";
import { getImageByKey } from "../../scripts/getImageByKey";
import classes from "./Pricing.module.css";

export default function Pricing() {
	return (
		<div className={classes.pricing}>
			<img src={getImageByKey("pricing")} alt="aboutus" height={"100%"} width={"100%"} />
		</div>
	);
}
