import React, { useEffect, useState } from "react";

import { useMyContext } from "../../../contexts/StateHolder";
import { createAssetIdToken } from "../../../scripts/utils";
import Carousel from "react-multi-carousel";

import settings from "../../../config_settings.json";
import { findSubOrganizationSecret, getAsset } from "../../../scripts/dataHandlers";
import RenderItem from "../../ViewAssets/RenderItems/RenderItem";

import classes from "./UpcomingEvent.module.css";
import Loader from "react-loader-spinner";
import Header from "../../../Shared/Header/Header";
import { useTranslation } from "react-i18next";

export default function EventAllRecordings({ singleEvent, orgId, eventId }) {
	const { t } = useTranslation();
	const [recrodingAssets, setRecrodingAssets] = useState(null);
	const [loading, setLoading] = useState(true);

	const { language } = useMyContext();
	console.log({ singleEvent, orgId, eventId });

	useEffect(() => {
		if (language) {
			let allRecordingsAsset = (
				singleEvent?.isMultiroom ? singleEvent?.rooms[0].recordings : singleEvent?.recordings
			)?.map((el) => el?.assetId);

			const runFunction = async () => {
				// lets find secret of orgId
				let subKey = await findSubOrganizationSecret(
					settings.organization.organizationId,
					settings.organization.key,
					orgId
				);

				subKey = subKey?.secret;
				console.log("subKey", subKey);
				let allAssets = [];

				await Promise.all(
					allRecordingsAsset.map(async (el) => {
						let token = createAssetIdToken(orgId, el, language, subKey);

						const res = await getAsset(orgId, el, token, language);
						allAssets.push(res);
					})
				);

				setRecrodingAssets(allAssets);
				setLoading(false);
			};

			runFunction();
		}
	}, [language]);

	return (
		<div className={classes.EventTertairy} style={{ marginTop: "80px" }}>
			{!loading && recrodingAssets?.length > 0 ? (
				<>
					<Header title={t("eventAllRecording.eventRecording")} color={"#161b55"} />
					<Carousel {...settings.components.eventAllRecordings.slickSettings}>
						{recrodingAssets.map((item, i) => {
							console.log("itemrec", item);
							let pushRoute = `/event/${orgId}/${eventId}/${item?.id}`;

							return (
								<RenderItem
									key={`${item?.id} ${i}`}
									item={item}
									isEventRecording={true}
									isEventRecordingIndex={i}
									pushRoute={pushRoute}
									playOnClick={true}
									itemImageComponent={"ItemImage1"}
									itemTitleComponent={"ItemTitle1"}
									textStyle={{ color: "#161b55" }}
									imageType={"thumbnail"}
									showCategoryName={true}
									showPlayIcon={true}
									showDuration={false}
									showReleaseYear={false}
									// whether to maintain aspect ratio 16/9
									maintainImageAspectRatio={true}
								/>
							);
						})}
					</Carousel>
				</>
			) : (
				<>
					<div className="display-flex-center">
						{" "}
						<Loader type="TailSpin" color="#3aaa35" />{" "}
					</div>
				</>
			)}
		</div>
	);
}
