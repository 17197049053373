import { useMyContext } from "../../contexts/StateHolder";
import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import RadiantPlayer from "./RadiantPlayer.js";
import { findSubOrganizationSecret, getPlayerConfig } from "../../scripts/dataHandlers";
import { getImageByKey } from "../../scripts/getImageByKey";

import settings from "../../config_settings.json";

const AssetVideoPlayer = (props) => {
	console.log("props", props);
	const { organization, googleTrackingId } = settings;

	const { user, chosenItem, loading, setLoading } = useMyContext();
	const { asset, orgId } = useParams();

	const [assetId, setAssetId] = useState("");
	const [companyId, setCompanyId] = useState("");
	const [groupId, setGroupId] = useState("");
	const [organizationId, setOrganizationId] = useState("");
	const [secretKey, setSecretKey] = useState("");
	const [userId, setUserId] = useState(0);

	const [showPlayer, setShowPlayer] = useState("hidden");
	const [campaignID, setCampaignID] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (orgId && !secretKey) {
			if (organization.organizationId * 1 === (orgId * 1 || props.organizationId * 1)) {
				console.log("organizationId111", organizationId, organization);
				// Set organizationId
				setOrganizationId(organization.organizationId);
				// Set companyId
				setCompanyId(organization.companyId);
				// Set groupId
				setGroupId(organization.groupId);
				// Set secretKey
				setSecretKey(organization.key);
				const checkAdvertisementPlayerConfig = async () => {
					const res = await getPlayerConfig();
					console.log("resplayerconfig1", res);
					const campaignId = res?.data?.items?.find(
						(el) => el.title === "player-campaigns-list"
					).value;
					console.log(campaignId);

					if (campaignId && campaignId * 1 > 0) setCampaignID(campaignId);
					console.log(campaignId);

					setLoading(false);
				};
				!props.isEvent && checkAdvertisementPlayerConfig();
			} else {
				// TODO: GET SUBORGANIZATION DATA FROM SOMEWHERE

				const getAllSubOrganization = async () => {
					let subKey = await findSubOrganizationSecret(
						organization.organizationId,
						organization.key,
						orgId
					);
					// console.log('subKey', subKey);
					const wantedSubOrg = {
						organizationId: orgId * 1,
						companyId: 10154,
						groupId: orgId * 1 + 1,
						key: subKey?.secret,
					};

					// Set organizationId
					setOrganizationId(wantedSubOrg.organizationId);
					// Set companyId
					setCompanyId(wantedSubOrg.companyId);
					// Set groupId
					setGroupId(wantedSubOrg.groupId);
					// Set secretKey
					setSecretKey(wantedSubOrg.key);
					const checkAdvertisementPlayerConfig = async () => {
						const res = await getPlayerConfig();
						console.log("resplayerconfig", res);

						const campaignId = res?.data?.items?.find(
							(el) => el.title === "player-campaigns-list"
						).value;
						console.log(campaignId);

						if (campaignId && campaignId * 1 > 0) setCampaignID(campaignId);
						console.log(campaignId);

						setLoading(false);
					};
					!props.isEvent && checkAdvertisementPlayerConfig();
				};
				getAllSubOrganization();
			}

			setUserId(user?.userId || 0);
			setAssetId(asset * 1 || props.asset * 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId]);

	if (
		user &&
		assetId !== "" &&
		organizationId !== "" &&
		!loading &&
		secretKey &&
		(chosenItem || props.isEvent)
	) {
		return (
			<div className="maxContainerPrimary">
				<div className={`${props.embedPlayer ? "maxContainerEmbed" : "maxContainer"}`}>
					<RadiantPlayer
						companyId={companyId}
						groupId={groupId} // Group of organization
						organizationId={organizationId} // Id of organization
						itemId={assetId} // AssetId to play
						playerAutoStart={props.playerAutoStart}
						userId={userId} // Id of user, if undefined in token, use zero
						secretKey={secretKey} // Secret key for organization
						backRoute={props.backRoute} // Route, where back button takes user. If undefined, it takes automatically to previous route
						backButton={props.backButton}
						setShowPlayer={setShowPlayer}
						campaignIDD={campaignID}
						isSerie={props.isSerie}
						googleTrackingId={googleTrackingId}
						// if it is an event we can pass the event here and later take all the teams and organization value to pass to mux data
						event={props?.event}
					/>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default AssetVideoPlayer;
