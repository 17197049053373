//import { useState } from "react";
import { useTranslation } from "react-i18next";
//import { changePassword, updateUser } from "../../scripts/dataHandlers";
import ProfileHeader from "../ProfileHeader";
import classes from "../../../Shared/Input/Input.module.css";
import Header from "../../../Shared/Header/Header";
import Subscription from "./Subscription/Subscription";
import classes2 from "./CurrentSubscription.module.css";
import { useMyContext } from "../../../contexts/StateHolder";
import { useCallback, useEffect } from "react";
import settings from "../../../config_settings.json";
import { getPackages, getUser, getUserTickets } from "../../../scripts/dataHandlers";
import { useCookies } from "react-cookie";

const CurrentSubscription = (props) => {
	// Bring stateholders from context
	const { user, setUser, language, setProfilePackages } = useMyContext();

	// Setup translate function
	const { t } = useTranslation();
	const [cookies] = useCookies("");
	const getPackagesData = useCallback(async () => {
		try {
			const responseData = await getPackages(settings.organization.organizationId, language, 0);

			console.log(`packagesData`, responseData);

			if (responseData.status === "ok" && typeof responseData.packages !== "undefined") {
				console.log("set progilePack");
				setProfilePackages(responseData.packages);
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		async function getUserData() {
			try {
				const response = await getUser(
					cookies?.userData?.userToken,
					settings.organization.organizationId
				);
				console.log("products:", response.data);

				if (response?.data?.buyerProducts) {
					user.buyerProducts = response.data.buyerProducts;
				}
			} catch (err) {
				console.log(err);
			}
		}

		if (user && !user?.buyerProducts) {
			getUserData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);
	useEffect(() => {
		getPackagesData();
	}, []);

	//THIS EFFECT WILL NOW FETCH TICKETS AND SAVE RESPONSE TO USER
	useEffect(() => {
		async function getUserTicketData() {
			try {
				const response = await getUserTickets(
					settings.organization.organizationId,
					cookies?.userData?.userToken,
					language
				);
				console.log(response.data.data);

				if (response?.data?.data) {
					//setTicketPurchaseHistory(response.data.data);
					user.ticketProducts = response.data.data;
					setUser({ ...user });
				}
			} catch (err) {
				console.log(err);
			}
		}

		if (user && !user?.ticketProducts) {
			getUserTicketData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	console.log("user", user);

	return (
		<>
			<div className={classes.formProfilePrimary}>
				<div className={classes.formSecondary}>
					<form className={classes.formBlog}>
						<ProfileHeader showSubscriptions={props.showSubscriptions} />

						<Header title={`${t("subscriptions.productPurchases")}`} extraClassname />
						{user.buyerProducts?.length > 0 ? (
							user.buyerProducts.map((product, i) => <Subscription product={product} count={i} />)
						) : (
							<div className={classes2.item}>
								<div className={`${classes2.itemName} font-400`}>
									{t("subscriptions.youHaveNoProducts")}
								</div>
							</div>
						)}
					</form>
				</div>
			</div>
		</>
	);
};

export default CurrentSubscription;
