import React from "react";
import classes from "./Paytrail.module.css";
import PaytrailItem from "./PaytrailItem";

export default function Paytrail({
	bankPaymentResponse,
	mobilePaymentResponse,
	cardPaymentResponse,
	paytrailResponse,
}) {
	return (
		<>
			{mobilePaymentResponse?.length > 0 && (
				<>
					<div className={`${classes.paymentMethodTitle} font-500`}>Mobile payment methods</div>
					<div className={classes.PaytrailForm}>
						{mobilePaymentResponse.map((el) => (
							<PaytrailItem provider={el} key={el?.name} />
						))}
					</div>
				</>
			)}
			{cardPaymentResponse?.length > 0 && (
				<>
					<div className={`${classes.paymentMethodTitle} font-500`}>Card payment methods</div>
					<div className={classes.PaytrailForm}>
						{cardPaymentResponse.map((el) => (
							<PaytrailItem provider={el} key={el?.name} />
						))}
					</div>
				</>
			)}
			{bankPaymentResponse?.length > 0 && (
				<>
					<div className={`${classes.paymentMethodTitle} font-500`}>Bank payment methods</div>
					<div className={classes.PaytrailForm}>
						{bankPaymentResponse.map((el) => (
							<PaytrailItem provider={el} key={el?.name} />
						))}
					</div>
				</>
			)}
			<div
				dangerouslySetInnerHTML={{ __html: paytrailResponse.terms }}
				className={`${classes.terms} font-300`}
			></div>
		</>
	);
}
