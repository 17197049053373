import { NavLink } from "react-router-dom";
import classes from "./NavBarButton.module.css";
import { useMyContext } from "../../contexts/StateHolder";
import { FiSearch } from "react-icons/fi";

const NavBarButton = (props) => {
	const { closeHamMenu } = useMyContext();

	return (
		<li className="menu-item">
			{" "}
			<NavLink
				key={props.name}
				style={props?.styles?.navBarBTN}
				to={`/${props.route}`}
				exact
				activeClassName={classes.activeSelected}
				onClick={closeHamMenu}
				className={classes.navBarSearch}
			>
				{props.icon === "FaSearch" ? <FiSearch className={classes.FaIcon} /> : null}
				{props.name && <div>{props.name}</div>}
			</NavLink>
		</li>
	);
};

export default NavBarButton;
