import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../contexts/StateHolder";
import PackageComponent from "../PackageComponent/PackageComponent";
import classes from "./PackagesView.module.css";
import { useForm } from "../../../Shared/Hooks/form-hook";
import {
	VALIDATOR_EMAIL,
	VALIDATOR_PASSWORDMATCH,
	VALIDATOR_REQUIRE,
} from "../../../Shared/Validation/Validator";
import { Input } from "../../../Shared/Input/Input";
import { useCookies } from "react-cookie";

//prints title and all the packages
const PackagesView = (props) => {
	const { buyButtonAction, profileData } = props;
	const { packages } = useMyContext();
	const [cookies, setCookie] = useCookies(["ue"]);

	const { t } = useTranslation();

	const [state, InputHandler] = useForm(
		{
			EMAIL: {
				value: "",
				isValid: false,
			},
		},
		false
		// the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
	);
	console.log(cookies.ue, "cookie ");
	return (
		<div className={classes.packagesView}>
			<div className={classes.packageHelp}>
				<div className={classes.packageHelpTitle}>{t("packages.PackageHelpTitle")}</div>
				<div className={classes.packageHelpText}>
					{t("packages.PackageHelpText")}
					<br />
					<br />
					{t("packages.PackageHelpText2")}
					<br />
					<br />
					{/* <strong>{t('packages.PackageHelpEmail')}</strong> */}

					{/* <div className={classes.packageHelp_input}>
                        <Input
                            id="EMAIL"
                            label="EMAIL"
                            placeholder={"ENTER YOUR EMAIL"}
                            type="text"
                            element="input"
                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
                            // errorText={'please enter valid email'}
                            onInput={InputHandler}
                            iconName="envelope"
                            style={{ margin: "0" }}
                            initialValue={cookies.ue}
                            initialValid={cookies.ue ? true : false}
                            disabled={true}
                        />
                    </div> */}
				</div>
			</div>
			<div key="packages" className={classes.packages}>
				{Object.values(packages).map((value) => (
					<PackageComponent
						key={value.id}
						pkg={value}
						buyButtonAction={buyButtonAction}
						userPackages={profileData.buyerProducts ? profileData.buyerProducts : []}
						isValid={true}
						email={state.inputs.EMAIL.value}
					/>
				))}
			</div>
		</div>
	);
};

export default PackagesView;
