import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";
import { getEventDescription, getEventTitle, momentDate } from "../../../scripts/utils";
import Header from "../../../Shared/Header/Header";
import BuyTickets from "./BuyTickets";
import classes from "./UpcomingEvent.module.css";
import { useMyContext } from "../../../contexts/StateHolder";

const FutureEvents = ({
	days,
	hours,
	minutes,
	seconds,
	routes,
	singleEvent,
	defaultLanguage,
	ticketAccessGranted,
	setTicketAccessGranted,
	ticketAccessRequired,
}) => {
	const { t } = useTranslation();
	const { language } = useMyContext();

	return (
		<div className={`${`${classes.EventTertairy} smallcasebold`} smallcasebold`}>
			<div className={classes.EventPrimary}>
				<div className={classes.EventSecondary}>
					<Header
						extraClassname={true}
						title={getEventTitle(singleEvent, language)}
						color={"#161b55"}
						admin={true}
					/>
					<div className={`${classes.EventPrimary_countdown} font-800`}>
						<div className={classes.EventPrimary_countdown_description}>
							{" "}
							<span>{zeroPad(days)}</span>
							<p className="font-300">{t("eventsCategory.days")}</p>
						</div>
						<div className={classes.EventPrimary_countdown_description}>
							<span>{zeroPad(hours)}</span>
							<p className="font-300">{t("eventsCategory.hours")}</p>
						</div>
						<div className={classes.EventPrimary_countdown_description}>
							<span>{zeroPad(minutes)}</span>

							<p className="font-300">{t("eventsCategory.minutes")}</p>
						</div>
						<div className={classes.EventPrimary_countdown_description}>
							<span>{zeroPad(seconds)}</span>

							<p className="font-300">{t("eventsCategory.seconds")}</p>
						</div>
					</div>
					{ticketAccessRequired && !ticketAccessGranted && (
						<BuyTickets
							routes={routes}
							futureEvents={true}
							singleEvent={singleEvent}
							setTicketAccessGranted={setTicketAccessGranted}
						/>
					)}
					{/* // show when ticket required and user have inserted ticket number */}
					{ticketAccessRequired && ticketAccessGranted && (
						<div className={`${classes.EventPrimary_countdown} font-600`}>
							{t("eventsCategory.eventNotStarted")}:<br />
						</div>
					)}
					<div className={classes.EventTimeDescription}>
						<div className={`${classes.EventPrimary_date} font-300`}>
							<div>
								<FontAwesomeIcon icon="calendar" />
							</div>

							<div>
								{momentDate(
									singleEvent?.startTimePublic
										? singleEvent?.startTimePublic
										: singleEvent?.startTime,
									language
								)}
							</div>
						</div>
						<div className={`${classes.EventPrimary_time} font-300`}>
							<div>
								{" "}
								<FontAwesomeIcon icon="clock" />
							</div>
							<div>
								{moment(
									singleEvent.startTimePublic
										? singleEvent?.startTimePublic
										: singleEvent?.startTime
								)
									.locale("fi")
									.format("LT")}
							</div>{" "}
						</div>
					</div>
					<div
						className={classes.EventPrimary_description}
						dangerouslySetInnerHTML={{
							__html: getEventDescription(singleEvent, language),
						}}
						style={{ paddingBottom: "30px" }}
					></div>
				</div>
			</div>
		</div>
	);
};

export default FutureEvents;
