import * as AlertDialog from "@radix-ui/react-alert-dialog";

import { useState } from "react";
import { useMyContext } from "../../contexts/StateHolder";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import settings from "../../config_settings.json";

import { authenticateUser, selfDeleteUser } from "../../scripts/dataHandlers";
import { useDeleteAccountModal } from "../../Shared/Hooks/useDeleteAccountModal";

import "./user-delete-style.css";
import { toast } from "react-toastify";

export const UserDeleteModal = ({ children }) => {
	const deleteModal = useDeleteAccountModal();
	const { t } = useTranslation();

	const { user } = deleteModal.data;
	const { setUser, setUserLoggedIn } = useMyContext();

	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies, removeCookie] = useCookies("");
	const history = useHistory();

	const onConfirm = async (e) => {
		e.preventDefault();
		console.log("user", user);
		setError(null);
		setIsLoading(true);

		const res = await authenticateUser(settings.organization.organizationId, user?.email, password);
		let currentUserToken = res?.data?.user_token;
		let userId = res?.data?.user_id;
		if (!currentUserToken && res?.data?.errorCode === "WRONGCREDENTIALS") {
			setError(true);
			setIsLoading(false);

			return;
		}
		if (!currentUserToken) {
			setIsLoading(false);

			return toast.error(t("shared.errorGeneric"));
		}
		// NOW DELETE THE ACTUAL USER

		const response = await selfDeleteUser(userId, currentUserToken);

		if (response?.status === "ok") {
			toast.success(t("deleteModal.accountDeletedSuccessfully"));

			setTimeout(() => {
				setUser({});

				setUserLoggedIn(false);
				if (cookies?.userData) {
					removeCookie("userData", 1, { path: "/", expires: new Date(Date.now()) });
				}
				if (cookies?.redirectUrl) {
					removeCookie("redirectUrl", 1, { path: "/", expires: new Date(Date.now()) });
				}
				if (cookies?.ue) {
					removeCookie("ue", 1, { path: "/", expires: new Date(Date.now()) });
				}
				if (cookies?.osec) {
					removeCookie("osec", 1, { path: "/", expires: new Date(Date.now()) });
				}

				setIsLoading(false);
				deleteModal?.onClose();

				history.push("/");

				window.location.reload();
			}, 1000);
		} else {
			setIsLoading(false);

			return toast.error(t("shared.errorGeneric"));
		}
	};

	const onClose = () => {
		setError(null);
		setIsLoading(false);
		setPassword("");
		deleteModal?.onClose();
	};
	return (
		<AlertDialog.Root open={deleteModal?.isOpen}>
			<AlertDialog.Trigger asChild>{children}</AlertDialog.Trigger>
			<AlertDialog.Portal>
				<AlertDialog.Overlay className="AlertDialogOverlay" />

				<form onSubmit={onConfirm}>
					<AlertDialog.Content className="AlertDialogContent">
						<AlertDialog.Title className="AlertDialogTitle">
							{t("deleteModal.deleteAccount")}?
						</AlertDialog.Title>
						<AlertDialog.Description className="AlertDialogDescription">
							{t("deleteModal.deleteDescription")}
						</AlertDialog.Description>
						<input
							type="password"
							required
							className="inputDelete"
							value={password}
							onChange={(e) => {
								setError(null);
								setPassword(e.target.value);
							}}
							disabled={isLoading}
							style={{
								borderColor: error ? "red" : "unset",
								border: "1px solid",
							}}
						/>
						{error && (
							<AlertDialog.Description className="AlertDialogDescription password-error">
								{t("deleteModal.invalidPassword")}
							</AlertDialog.Description>
						)}

						<div style={{ display: "flex", gap: 10, justifyContent: "flex-end" }}>
							<button className="Button mauve" onClick={onClose} type="button">
								{t("deleteModal.cancel")}
							</button>
							<button className="Button red" disabled={!password || isLoading} type="submit">
								{t("deleteModal.confirmDeleteAccount")}
							</button>
						</div>
					</AlertDialog.Content>
				</form>
			</AlertDialog.Portal>
		</AlertDialog.Root>
	);
};
