import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { checkTicketNumber } from "../../../scripts/dataHandlers";
import AssetVideoPlayer from "../../VideoPlayer/AssetVideoPlayer";
import EventsRoomDescription from "./EventsRoomDescription";

import classes from "./UpcomingEvent.module.css";
import EventAllRecordings from "./EventAllRecordings";
import { useParams } from "react-router";
import Loader from "react-loader-spinner";

const CompletedEventWithRecording = ({
	routes,
	singleEvent,
	setTicketAccessGranted,
	ticketAccessGranted,
	ticketAccessRequired,
	recordingAssetId,
	isLiveEvents,
	defaultLanguage,
	setRecordingAssetId,
}) => {
	const [cookies, setCookie, removeCookie] = useCookies(["tiAcc"]);
	const [recordingAssetsNumber, setRecordingAssetsNumber] = useState(0);
	const [eventRecordingLoading, setEventRecordingLoading] = useState(true);
	const { orgId, eventId, recordingId } = useParams();

	const [completedRecordingAssetId, setCompletedRecordingAssetId] = useState(null);

	console.log("first", ticketAccessGranted, ticketAccessRequired, "second");

	const checkForVideoAccess = useCallback(async () => {
		try {
			console.log(singleEvent);
			const setRecordingNum = (setRecordAsset) => {
				console.log({ recordingId });
				setRecordAsset &&
					setCompletedRecordingAssetId(
						recordingId
							? recordingId
							: singleEvent?.isMultiroom
							? singleEvent?.rooms[0].recordings[0]?.assetId
							: singleEvent?.recordings[0]?.assetId
					);
				if (
					singleEvent?.isMultiroom
						? singleEvent?.rooms[0].recordings?.length > 1
						: singleEvent?.recordings?.length > 1
				) {
					// this means there are more than 1 recording for that particular event and we need to show them in allrecordings

					setRecordingAssetsNumber(
						singleEvent?.isMultiroom
							? singleEvent?.rooms[0].recordings?.length
							: singleEvent?.recordings?.length
					);
				}
				setEventRecordingLoading(false);
			};
			if (!ticketAccessRequired && ticketAccessGranted) {
				//this means this is a package and user has already bought this

				setRecordingNum(true);
			} else if (singleEvent?.accessControls?.length > 0) {
				// this is paid event
				const res = await checkTicketNumber(singleEvent?.eventId, cookies?.tiAcc);
				console.log("res", res);

				if (res?.data?.status === "ok") {
					setCookie("tiAcc", cookies?.tiAcc, {
						path: "/",
					});
					setRecordingNum(true);
					setTicketAccessGranted(true);
				} else {
					removeCookie("tiAcc", { path: "/" });
					setTicketAccessGranted(false);

					setEventRecordingLoading(false);
				}
			} else if (!ticketAccessGranted && ticketAccessRequired) {
				// this means it is a package and user have not bought it
				setRecordingNum();

				setTicketAccessGranted(false);
			} else {
				// free event
				setRecordingNum();
				setTicketAccessGranted(true);
			}
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		if (singleEvent?.eventId) {
			console.log(1);
			checkForVideoAccess();
		}
	}, [singleEvent?.eventId]);

	useEffect(() => {
		if (recordingId) {
			setEventRecordingLoading(true);
			setRecordingAssetId(recordingId);
			setTimeout(() => {
				setEventRecordingLoading(false);
			}, 1000);
		}
	}, [recordingId]);

	return eventRecordingLoading ? (
		<div className="display-flex-center">
			{" "}
			<Loader type="TailSpin" color="#3aaa35" />{" "}
		</div>
	) : (
		singleEvent && (
			<>
				{/* // this will work when there is access control applied to recording , wee have prevented a loophole by passing completedRecordingAssetId as a parameter */}
				{ticketAccessRequired && completedRecordingAssetId && cookies?.tiAcc && (
					<div className={classes.SecondaryContainer}>
						<AssetVideoPlayer asset={completedRecordingAssetId} isEvent={true} />
					</div>
				)}
				{/* // free event */}
				{ticketAccessGranted && !ticketAccessRequired && (
					<div className={classes.SecondaryContainer}>
						<AssetVideoPlayer asset={recordingAssetId} isEvent={true} />
					</div>
				)}
				<EventsRoomDescription
					liveEvent={isLiveEvents}
					routes={routes}
					ticketAccessGranted={ticketAccessGranted}
					singleEvent={singleEvent}
					ticketAccess={ticketAccessRequired}
					defaultLanguage={defaultLanguage}
					setTicketAccessGranted={setTicketAccessGranted}
					futureEvents={false}
					hideBuyButton={true}
				/>
				{ticketAccessGranted && recordingAssetsNumber >= 1 && (
					<EventAllRecordings singleEvent={singleEvent} orgId={orgId} eventId={eventId} />
				)}
			</>
		)
	);
};

export default CompletedEventWithRecording;
