import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import settings from "../../config_settings.json";
import { requestResetPassword } from "../../scripts/dataHandlers";
import { resetPasswordToken } from "../../scripts/utils";
import Button from "../../Shared/Button/Button";
import { useForm } from "../../Shared/Hooks/form-hook";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../../Shared/Validation/Validator";
import classes from "../../Shared/Input/Input.module.css";
import { Input } from "../../Shared/Input/Input";

export default function ResetPasswordForm() {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [resetEmailSuccess, setResetEmailSuccess] = useState(false);
	const { t } = useTranslation();

	const [state, InputHandler] = useForm(
		{
			EMAIL: {
				value: "",
				isValid: false,
			},
		},
		false
	);
	const formSubmitHandler = async (e) => {
		e.preventDefault();
		setError(null);
		setLoading(true);

		const token1 = resetPasswordToken(
			state.inputs.EMAIL.value,
			settings.organization.organizationId,

			"IsmSubscriber",
			settings.organization.key
		);

		const res = await requestResetPassword(
			state.inputs.EMAIL.value,
			settings.organization.organizationId,

			"IsmSubscriber",
			token1
		);
		if (res?.data?.status === "ok") {
			setResetEmailSuccess(true);
		} else if (res?.data?.status === "error") {
			setError(`${t("resetPassword.emailNotExistInSystem")}`);
		} else {
			// this basically means our servers are down
			setError(`${t("shared.systemError")}`);
		}
		setLoading(false);
	};
	return resetEmailSuccess ? (
		<>
			<div className={classes.formPrimary}>
				<div className={classes.formSecondary}>
					<div
						className={`${classes.formBlog}
                        
                        
                        
                        `}
						style={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h2 className={`${classes.formHeader} font-700 smallcasebold`}>
							{t("resetPassword.resetPassword")}
						</h2>
						<p
							className="font-200 smallcasebold "
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								margin: "10px 0",
							}}
						>
							{t("resetPassword.resetPasswordCheckEmail")}
						</p>

						<Button className={classes.loginFormButton} formButton inverse to="/">
							{t("packages.ReceiptMoveToFront")}
						</Button>
					</div>
				</div>
			</div>
		</>
	) : (
		<div className={classes.formPrimary}>
			<div className={classes.formSecondary}>
				<form
					className={`${classes.formBlog} `}
					onSubmit={formSubmitHandler}
					style={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<h2 className={`${classes.formHeader} font-700 smallcasebold`}>
						{t("resetPassword.resetPassword")}
					</h2>
					{error && !loading && (
						<p
							className="font-300 smallcasebold "
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "#ab0303",
								margin: "10px 0",
							}}
						>
							{error}
						</p>
					)}

					<Input
						id="EMAIL"
						label={t("signUpBar.inputPlaceholderEmail")}
						placeholder={t("Enter your email here")}
						type="text"
						element="input"
						validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE()]}
						errorText={t("signUpBar.errorIncorrectEmailAddress")}
						onInput={InputHandler}
						iconName="envelope"
					/>

					<p
						className="font-200 smallcasebold "
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							margin: "10px 0",
						}}
					>
						{t("resetPassword.enterVerifiedEmail")}
					</p>

					<Button
						className={classes.loginFormButton}
						disabled={!state.isValid || loading}
						formButton
						inverse
					>
						{loading ? t("shared.loading") : t("resetPassword.requestResetPasswordLink")}
					</Button>
				</form>
			</div>
		</div>
	);
}
