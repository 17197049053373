import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./DetailsShare.module.css";
// import HelmetMetaData from '../ShareSocialMedia/HelmetMetaData';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import settings from "../../components/../config_settings.json";
import Modal from "react-modal";
Modal.setAppElement(document.body);

const DetailsShare = (props) => {
	const { organization } = settings;
	return (
		<>
			<div className={`${classes.details_share}`}>
				<FacebookShareButton
					url={window.location.href}
					quote={`${
						props.chosenItem?.serie?.title || props.chosenItem?.title || props.chosenItem?.name
					}\n\n
        ${
					props.chosenItem?.serie?.description ||
					props.chosenItem?.ingress ||
					props.chosenItem?.description
				}
        `}
					hashtag={`#${organization.name}`}
					image={
						props.chosenItem?.bannerImageSmall ||
						props.chosenItem?.coverImageSmall ||
						props.chosenImage?.thumbnailSmall
					}
					className={classes.socialMediaButton}
				>
					<FontAwesomeIcon icon={["fab", "facebook"]} className={`${classes.shareBTN} `} />
				</FacebookShareButton>

				<TwitterShareButton
					url={window.location.href}
					title={`${props.chosenItem?.title || props.chosenItem?.name}
          \n`}
					image={
						props.chosenItem?.bannerImageSmall ||
						props.chosenItem?.coverImageSmall ||
						props.chosenImage?.thumbnailSmall
					}
					hashtags={[...(organization.name ? [organization.name] : [])]}
					//via='Somenamehere, shows up like @something in end of tweet'
					className={classes.socialMediaButton}
				>
					<FontAwesomeIcon icon={["fab", "twitter"]} className={`${classes.shareBTN}  `} />
				</TwitterShareButton>
			</div>
		</>
	);
};

export default DetailsShare;
