import React from "react";
import classes from "./Paytrail.module.css";

export default function PaytrailItem({ provider }) {
	return (
		<form method="POST" action={provider.url}>
			{provider.parameters.map((el) => (
				<input type="hidden" name={el.name} value={el.value} key={el.name} />
			))}
			<button className={classes.PaytrailButton}>
				<img src={provider.svg} alt={provider.name} />
			</button>
		</form>
	);
}
