import { useTranslation } from "react-i18next";
import { addZeroes, convertCurrency } from "../../../scripts/utils";
import classes from "./PackageComponent.module.css";
import { useMyContext } from "../../../contexts/StateHolder";
import { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import Button from "../../../Shared/Button/Button";

//shows single package information. Doesn't handle ticket packages or f-liiga single event purchase packages yet.
const PackageComponent = ({ pkg, buyButtonAction, userPackages, isValid, email }) => {
	const { t } = useTranslation();
	const [cookies, setCookie] = useCookies(["ue"]);

	console.log("pkgname", pkg);

	const { user, userEmail, setUserEmail, ticketQuantity, setTicketQuantity } = useMyContext();

	const increaseTicketQuantity = () => {
		console.log(pkg?.quantity * 1, pkg);
		// package quanity is 0 means we can add unlimited product
		if (pkg?.quantity === 0) {
			setTicketQuantity(ticketQuantity + 1);
		} else {
			if (ticketQuantity >= pkg?.quantity * 1) return;
			setTicketQuantity(ticketQuantity + 1);
		}
	};
	const decreaseTicketQuantity = () => {
		if (ticketQuantity <= 1) return;

		setTicketQuantity(ticketQuantity - 1);
	};

	const checkForLogin = (el) => {
		if (!user.loggedIn && !userEmail && !cookies?.ue) {
			// we will need UsemEmail to send the bill so we will open modal
		} else if (!user.loggedIn && (userEmail || cookies?.ue)) {
			// user wnats to buy from email
			return buyButtonAction(el);
		} else if (user.loggedIn) {
			// buy as a loggedIn user
			return buyButtonAction(el);
		}
	};
	useEffect(() => {
		if (email) {
			setUserEmail(email);
			setCookie("ue", email, {
				path: "/",
			});
		}
	}, [email, setCookie, setUserEmail]);
	console.log(pkg?.category?.contentType);

	return (
		<>
			<div className={classes.packageComponent} key={pkg.id}>
				<div className={classes.packageTitle}>{pkg.name}</div>
				<div className={classes.packagePrice}>
					{convertCurrency(pkg.currency)} {addZeroes(pkg.price)}
				</div>
				<div
					className={classes.packageDescription}
					dangerouslySetInnerHTML={{ __html: pkg.description }}
				/>
				<div className={classes.packageBuyButtonContainer}>
					{userPackages.findIndex(
						(userPkg) =>
							userPkg.sku === pkg.sku &&
							userPkg.validFrom < Date.now() &&
							userPkg.validTo > Date.now()
					) !== -1 ? (
						<div id={pkg.id} name={pkg.id}>
							<Button className={classes.packageBoughtButtonText}>{t("packages.Bought")}</Button>
						</div>
					) : (
						<div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
							{pkg?.category?.contentType === 3 && pkg?.quantity > 1 && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										gap: "10px",
										marginTop: "5px",
									}}
								>
									{" "}
									<div style={{ fontWeight: 600 }}>Quantity</div>
									<div
										style={{
											display: "flex",
											justifyContent: "space-around",
											alignItems: "center",
											gap: "5px",
										}}
									>
										<Button
											onClick={decreaseTicketQuantity}
											disabled={ticketQuantity === 1}
											style={{
												padding: "10px 20px ",
												height: "38px",
											}}
										>
											-
										</Button>
										<input
											value={ticketQuantity}
											style={{
												width: "50px",
												flex: 1,
												textAlign: "center",
												height: "38px",
												outline: "none",
												border: "none",
											}}
										/>

										<Button
											onClick={increaseTicketQuantity}
											disabled={pkg?.quantity !== 0 && ticketQuantity === pkg?.quantity}
											style={{
												padding: "10px  20px",
												height: "38px",
											}}
											inverse
										>
											+
										</Button>
									</div>
								</div>
							)}
							<div id={pkg.id} name={pkg.id} onClick={() => checkForLogin(pkg)}>
								{pkg?.category?.contentType === 3 ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItem: "center",
										}}
									>
										<Button className={classes.packageBuyButtonText} disabled={!isValid}>
											{t("packages.Order")}
											{/* Buy Ticket */}
										</Button>
									</div>
								) : (
									<Button className={classes.packageBuyButtonText} disabled={!isValid}>
										{t("packages.Order")}
									</Button>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PackageComponent;
