import React from "react";
import classes from "./Header.module.css";

export default function Header({ showTitle, extraClassname, title, admin }) {
	console.log(showTitle, extraClassname, title, admin);

	return (
		<div
			className={`${classes.HeaderTitle}   ${extraClassname ? "font-600" : "font-500"}
        ${admin ? classes.HeaderTitleAdmin : ""}
        
        `}
		>
			{showTitle !== false ? title : null}
		</div>
	);
}
