import React, { useState } from "react";
import classes from "../../Shared/Input/Input.module.css";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../Shared/Header/Header";
import { useTranslation } from "react-i18next";
import { Input } from "../../Shared/Input/Input";
import { VALIDATOR_MINLENGTH, VALIDATOR_PASSWORDMATCH } from "../../Shared/Validation/Validator";
import Button from "../../Shared/Button/Button";
import { useForm } from "../../Shared/Hooks/form-hook";
import { resetPasswordChange } from "../../scripts/dataHandlers";
import { useHistory } from "react-router-dom";

export default function ResetPasswordTokenValid({ tokenFromEmail }) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const [state, InputHandler] = useForm(
		{
			PASSWORD: {
				value: "",
				isValid: false,
			},
			PASSWORDCONFIRM: {
				value: "",
				isValid: false,
			},
		},
		false
	);

	const formSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);

		const response = await resetPasswordChange(
			state.inputs.PASSWORD.value,
			state.inputs.PASSWORDCONFIRM.value,
			tokenFromEmail
		);
		console.log("res", response);
		response?.data?.status === "ok"
			? toast.success(t("changePassword.changedSuccessfully"), {
					autoClose: 10000,
					closeOnClick: true,
					draggable: true,

					theme: "dark",
			  })
			: response?.data?.message
			? toast.error(response?.data?.message, {
					autoClose: 10000,
					closeOnClick: true,
					draggable: true,

					theme: "dark",
			  })
			: toast.error(t("changePassword.somethingWrongTryAgain"), {
					autoClose: 10000,
					closeOnClick: true,
					draggable: true,

					theme: "dark",
			  });

		if (response?.data?.status === "ok") {
			// we send them to main page
			setLoading(false);
			setTimeout(() => {
				// they cant come back
				history.replace("/");
			}, 1000);
		} else {
			setLoading(false);
		}
	};
	return (
		<>
			<ToastContainer />

			<div className={classes.formPrimary}>
				<div className={classes.formSecondary}>
					<form
						className={`${classes.formBlog} display-flex-center`}
						style={{ flexDirection: "column" }}
						onSubmit={formSubmitHandler}
					>
						<h2 className={`${classes.formHeader} font-700 smallcasebold`}>
							{t("profileMenu.changePassword")}
						</h2>

						<Input
							id="PASSWORD"
							placeholder={t("changePassword.askForNewPassword")}
							label={t("changePassword.askForNewPassword")}
							type="Password"
							element="input"
							validators={[VALIDATOR_MINLENGTH(5)]}
							errorText={t("changePassword.errorPasswordNotLongEnough", {
								count: "5",
							})}
							onInput={InputHandler}
							iconName="lock"
						/>
						<Input
							id="PASSWORDCONFIRM"
							label={t("changePassword.askForConfirmPassword")}
							placeholder={t("changePassword.askForConfirmPassword")}
							type="Password"
							element="input"
							validators={[
								VALIDATOR_PASSWORDMATCH(
									state.inputs.PASSWORD.value,
									state.inputs.PASSWORDCONFIRM.value
								),
							]}
							errorText={t("changePassword.errorPasswordsDontMatch")}
							onInput={InputHandler}
							iconName="lock"
						/>

						<Button
							className={classes.loginFormButton}
							disabled={!state.isValid || loading}
							formButton
							inverse
						>
							{loading ? t("shared.loading") : t("changePassword.save")}
						</Button>
					</form>
				</div>
			</div>
		</>
	);
}
