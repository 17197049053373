import React from "react";
import { getImageByKey } from "../../../scripts/getImageByKey";
// import VideoHero from '../../VideoComponent/VideoHero';
import classes from "./ChannelVideos.module.css";
import { useHistory } from "react-router-dom";
import VideoHero from "../../VideoComponent/VideoHero";

export default function ChannelVideos(props) {
	console.log(props);
	const history = useHistory();
	const onClick = (item) => {
		history.push(`/${props.routes.playVideo}/${props.organizationId}/${item.id}`);
	};
	return (
		<div className={classes.PrimaryContainer}>
			<div className={classes.SecondaryContainer}>
				{props.videos && props.videos.length > 0 ? (
					props.videos.map((el) => (
						<VideoHero
							imageNameWeb={el.thumbnailLarge}
							title={el.name}
							onClick={() => onClick(el)}
						/>
					))
				) : (
					// <h2>on</h2>
					<div className={classes.NoDataAvailable}>
						<h2 className="font-400">No Videos</h2>
						<img src={getImageByKey("no_data_available")} alt="no_data_available" />
					</div>
				)}
			</div>
		</div>
	);
}
