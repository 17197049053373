import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// react date time range css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// toastify
import "react-toastify/dist/ReactToastify.css";
import "react-multi-carousel/lib/styles.css";

import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useMyContext } from "./contexts/StateHolder";

import settings from "./config_settings.json";
import BottomBar from "./components/Footers/BottomBar";

import { getCategories } from "./scripts/dataHandlers";
import i18n from "./i18n/config";

import "./Shared/FontAwesomeIcon.js";

import ChosenCategoryGrid from "./components/ViewAssets/Grids/ChosenCategoryGrid";

import SeriesAsset from "./components/ViewAssets/Sliders/SeriesAsset/SeriesAsset";

import ChannelsMainPage from "./components/Channels/Channels/ChannelsMainPage";
import DetailsSerie from "./components/Details/DetailsSerie";

import ContactUs from "./components/ContactUs/ContactUs";
import Pricing from "./components/Pricing/Pricing";

import Channel from "./components/Channels/Channel/Channel";
import { useCookies } from "react-cookie";
// import DetailsVideoNew from './components/Details/DetailsVideoNew';
import EventsCategoryItem from "./components/Events/EventsCategory/EventsCategoryItem";
import UpcomingEvent from "./components/Events/Event/UpcomingEvent";
import UpcomingEvents from "./components/Events/EventsCategory/UpcomingEvents";

import EventsCategory from "./components/Events/EventsCategory/EventsCategory";
import NavBarButton from "./components/NavBars/NavBarButton";
import NavBar from "./components/NavBars/NavBar";

import LoginBTN from "./components/Login/LoginBTN";
import classes from "./components/NavBars/NavBar.module.css";
import Banner from "./components/ViewAssets/Sliders/Banner";
import RenderCategoriesWithTitles from "./components/ViewAssets/Sliders/CategoriesWithTitles";
import Promo from "./components/ViewAssets/Sliders/Promo";
import LiveNowEvents from "./components/Events/EventsCategory/LiveNowEvents";

import RenderCategoryDropdown from "./components/DropDowns/CategoryDropdown";
import PurchaseFlow from "./components/Packages/PurchaseFlow";
import { useTranslation } from "react-i18next";
import TOS from "./components/TOS/TOS";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Search from "./components/Search/Search";
import DetailsVideo from "./components/Details/DetailsVideo";
import LoginForm from "./components/Login/LoginForm";
import SignUpForm from "./components/SignUp/SignUpForm";

import AssetVideoPlayer from "./components/VideoPlayer/AssetVideoPlayer";
import Profile from "./components/Profile/Profile";
import AboutUs from "./components/AboutUs/AboutUs";

import FAQ from "./components/FAQ/FAQ";
import PastEvents from "./components/Events/EventsCategory/PastEvents";
import EventsEditorChoice from "./components/Events/EventsEditorChoice/EventsEditorChoice";
import RequestResetPassword from "./pages/RequestResetPassword";
import Spinner from "./components/Spinner/Spinner";
import LanguageSelect from "./components/LanguageSelect/LanguageSelect";
import LanguageSelect1 from "./components/LanguageSelect/LanguageSelect1";
import FAQEn from "./components/FAQ/FAQEn";
import TOSEn from "./components/TOS/TOSEn";
import PrivacyPolicyEn from "./components/PrivacyPolicy/PrivacyPolicyEn";
import { UserDeleteModal } from "./components/modal/UserDeleteModal.js";

function App() {
	// Bring stateholders from context
	const {
		setAllCategories,

		setKey,

		setOrganizationId,

		setLanguage,
		isResponsiveclose,
		user,
		setUser,
		userLoggedIn,
		setAllLanguages,
		allLanguages,
	} = useMyContext();
	// organizationSecret
	const [cookies, setCookie] = useCookies(["osec"]);

	const { t } = useTranslation();
	const [authLoaded, setAuthLoaded] = useState(false);

	/**** STEP 1, INIT PROGRAM ****/
	useEffect(() => {
		async function initProgram() {
			try {
				// TODO: Import from suite, now importing just from settings.json

				// Get organization data (id and key)
				const getOrganization = settings.organization;

				// Set organizationId to context
				setOrganizationId(getOrganization.organizationId);

				// Set key to context
				setKey(getOrganization.key);

				// Get language from settings
				const getLanguage = settings.language;

				// Set language to context
				setLanguage(getLanguage);
				setAllLanguages(Object.keys(settings.languages).map((k) => settings.languages[k]));

				// Get categories from datahandler
				const res = await getCategories(
					getOrganization.organizationId,
					getOrganization.key,
					getLanguage
				);

				setAllCategories(res);
				setCookie("osec", getOrganization.key, { path: "/" });
			} catch (err) {
				console.log(err);
			}
		}

		initProgram();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	console.log(allLanguages);
	useEffect(() => {
		if (cookies?.userData?.userToken || userLoggedIn) {
			if (!user?.loggedIn && cookies?.userData?.userToken) {
				console.log(user);
				let modifiedUser = { ...user };

				// we will fill data from cookie if it is not there

				modifiedUser.loggedIn = true;

				modifiedUser.userId = cookies?.userData?.userId;

				modifiedUser.userToken = cookies?.userData?.userToken;

				modifiedUser.firstName = cookies?.userData?.firstName;

				modifiedUser.organizationId = cookies?.userData?.userOrganizationId;
				modifiedUser.eMail = cookies?.ue;
				modifiedUser.organizationName = cookies?.userData?.userOrganizationName;

				setUser(modifiedUser);
			}
			// getiing package of parent org ->- we need to match these later to check the event packages
			if (cookies?.lang) {
				if (cookies?.lang?.includes("en")) {
					setLanguage("en_GB");
					i18n.changeLanguage("en_GB");
				}
			}

			// getiing package of parent org ->- we need to match these later to check the event packages

			setTimeout(() => {
				setAuthLoaded(true);
			}, 100);
		} else {
			if (cookies?.lang) {
				if (cookies?.lang?.includes("en")) {
					setLanguage("en_GB");
					i18n.changeLanguage("en_GB");
				}
			}
			setAuthLoaded(true);
		}
	}, [cookies?.userData?.userToken, userLoggedIn, user?.loggedIn]);

	return authLoaded ? (
		<div className="App">
			<div className="containerNavbar">
				<NavBar
					links={
						<>
							<div className={classes.navBarRow_primary}>
								<NavBarButton route={settings.routes.events} name={t("navBar.events")} />
								<NavBarButton route={settings.routes.faq} name={t("navBar.faq")} />
							</div>
							<div className={classes.navRight}>
								{" "}
								{/* // show only in bigger screen */}
								{!isResponsiveclose && (
									<>
										<NavBarButton route={settings.routes.search} icon="FaSearch" />
									</>
								)}
								<LoginBTN routes={settings.routes} />
								{!user?.loggedIn && <LoginBTN routes={settings.routes} signUp />}
								<LanguageSelect1 />
							</div>
						</>
					}
					// small screen
					linksMobileLogin={
						<div className={classes.navRight}>
							<>
								<LoginBTN routes={settings.routes} hideLogoutSmallScreen={true} />
								<LoginBTN routes={settings.routes} signUp hideLogoutSmallScreen={true} />
							</>
						</div>
					}
					linksMobileSearch={
						<div className={classes.navRight}>
							{<NavBarButton route={settings.routes.search} icon="FaSearch" />}
						</div>
					}
				/>
			</div>
			<UserDeleteModal />

			<Switch>
				<Route path="/" exact>
					<Banner settings={settings.components.frontPageBanner_01} />

					<div style={{ paddingBottom: "20px" }}>
						<div style={{ width: "88%", margin: "0 auto" }}>
							<LiveNowEvents
								organizationId={settings.organization.organizationId}
								homepageContainer={true}
							/>
							<UpcomingEvents
								organizationId={settings.organization.organizationId}
								settings={settings.components.frontPageUpcomingEvents}
								homepageContainer={true}
							/>
							<PastEvents
								organizationId={settings.organization.organizationId}
								settings={settings.components.frontPageUpcomingEvents}
								homepageContainer={true}
							/>
						</div>
					</div>

					<div style={{ paddingBottom: "20px" }}>
						<div className="homePageContainer">
							{/* <SeriesAsset
                                    settings={settings.components.frontPageCategories_01}
                                    showDuration={false}
                                    showReleaseYear={true}
                                    showPublishedDate={true}
                                    hideDescription={true}
                                    latestData={true}
                                    titleName={t("seriesSlider.latest")}
                                /> */}
							{/* <SeriesAsset
                                    settings={settings.components.frontPageCategories_01}
                                    showDuration={false}
                                    showReleaseYear={true}
                                    showPublishedDate={true}
                                    hideDescription={true}
                                    titleName={t("seriesSlider.trending")}
                                /> */}
							<RenderCategoriesWithTitles
								settings={settings.components.frontPageCategories_02}
								imageType={"thumbnailSerie"}
								showDuration={false}
								showReleaseYear={true}
								showPublishedDate={true}
								hideDescription={true}
							/>
						</div>
					</div>
				</Route>

				<Route path={`/${settings.routes.packages}/:orgId/:itemType?/:itemId?`} exact>
					{!user?.loggedIn && (
						<LoginForm
							routes={settings.routes}
							redirect={false}
							showBgImage={false}
							redirectRoute={"/"}
						/>
					)}
					<PurchaseFlow settings={settings.components.purchaseflow} />
				</Route>

				<Route path={`/${settings.routes.svodCategoriesRoute}/:asset`} exact>
					<RenderCategoryDropdown route={settings?.routes.svodCategoriesRoute} />
					<ChosenCategoryGrid
						settings={settings.components.svodChosenCategoryGrid_01}
						imageType={"thumbnailSerie"}
						showDuration={false}
						showReleaseYear={true}
						showPublishedDate={true}
						hideDescription={true}
					/>

					{/* <CategoryContentSimiliar
         settings={settings.components.svodSimiliar_01}
         
       /> */}
				</Route>
				<Route path={`/${settings.routes.tos}`}>
					<TOS />
				</Route>
				<Route path={`/en/${settings.routes.tos}`}>
					<TOSEn />
				</Route>
				<Route path={`/${settings.routes.privacyPolicy}`}>
					<PrivacyPolicy />
				</Route>
				<Route path={`/en/${settings.routes.privacyPolicy}`}>
					<PrivacyPolicyEn />
				</Route>

				{/* Single Event page which is embedded from suite atm */}

				<Route path={`/${settings.routes.upcomingEvent}`} exact>
					<UpcomingEvent routes={settings.routes} />
				</Route>

				{/* We get Events based on categoryId and show them to user */}

				<Route path={`/${settings.routes.eventBasedOnCategory}`} exact>
					<div style={{ background: "", paddingBottom: "20px" }}>
						<div style={{ width: "88%", margin: "0 auto" }}>
							<EventsCategoryItem settings={settings.components.EventsCategoryItem} />
						</div>
					</div>
				</Route>
				{/* Events main page */}

				<Route path={`/${settings.routes.events}`} exact>
					<div style={{ background: "", paddingBottom: "20px" }}>
						<div style={{ width: "88%", margin: "0 auto" }}>
							<LiveNowEvents organizationId={settings.organization.organizationId} />
							<UpcomingEvents
								organizationId={settings.organization.organizationId}
								settings={settings.components.events}
							/>
							<PastEvents
								organizationId={settings.organization.organizationId}
								settings={settings.components.frontPageUpcomingEvents}
								homepageContainer={true}
							/>
							{/* <EventsCategory organizationId={settings.organization.
                                    
                                    organizationId} settings={settings.components.events} /> */}

							<EventsEditorChoice
								organizationId={settings.organization.organizationId}
								settings={settings.components.frontPageUpcomingEvents}
								homepageContainer={true}
								limit={4}
								categoryId={207219134}
							/>
						</div>
					</div>
				</Route>
				<Route path={`/${settings.routes.profile}`}>
					<Profile settings={settings.components.profile} />
				</Route>

				<Route path={`/${settings.routes.login}`}>
					<LoginForm routes={settings.routes} />
				</Route>

				{/* ROUTE FOR SIGNUP VIEW*/}

				<Route path={`/${settings.routes.signUp}`}>
					<SignUpForm
						routes={settings.routes}
						settings={settings.components.signUp}
						fields={settings.components.signUp.fields}
					/>
				</Route>
				{/* Channels main page */}
				<Route path="/channels/:orgId" exact>
					<ChannelsMainPage />
				</Route>

				{/* about us */}
				<Route path={`/${settings.routes.aboutus}`}>
					<AboutUs />
				</Route>
				<Route path={`/${settings.routes.pricing}`}>
					<Pricing />
				</Route>
				{/* channel Single page */}
				<Route path="/channels/:orgId/:subOrganizationId/:links?" exact>
					<Channel routes={settings.routes} />
				</Route>

				<Route path={`/${settings.routes.videoRoute}/:orgId/:asset`} exact>
					<AssetVideoPlayer backRoute={"/"} playerAutoStart={true} />

					<DetailsVideo
						styles={settings.styles}
						routes={settings.routes}
						hideBanner={true}
						hideInfoData={true}
						showPublishedDate={true}
					/>
					{/* <CategorySimiliar
         settings={settings.components.svodSimiliar_01}
         
       /> */}
				</Route>
				<Route path={`/${settings.routes.svodSerieRoute}/:asset`} exact>
					<DetailsSerie styles={settings.styles} />
				</Route>
				<Route path={`/${settings.routes.contactus}`}>
					<ContactUs />
				</Route>
				{/* ROUTE FOR SEARCH VIEW*/}
				<Route path={`/${settings.routes.search}`}>
					<Search settings={settings.components.search_01} />
				</Route>
				<Route path={`/${settings.routes.faq}`}>
					<FAQ />
				</Route>
				<Route path={`/en/${settings.routes.faq}`}>
					<FAQEn />
				</Route>
			</Switch>

			<Route path={`/${settings.routes.requestResetPassword}/:token?`}>
				<div className="main-container" style={{ flex: 1 }}>
					<RequestResetPassword />
				</div>
			</Route>

			<BottomBar routes={settings.routes} />
		</div>
	) : (
		<Spinner />
	);
}

export default App;
