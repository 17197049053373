import React from "react";
import classes from "../ChannelsMainPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useMyContext } from "../../../contexts/StateHolder";
import VideosImage from "../../VideoComponent/VideosImage";

export default function Channelsitem(props) {
	const { setSubOrganizationDetails } = useMyContext();
	const { channel } = props;
	const history = useHistory();

	const clickhandler = (el) => {
		console.log(el);
		setSubOrganizationDetails(el);

		history.push(`/channels/${props.organizationId}/${el.organizationId}`);
	};
	console.log(props.channellogoUrl, props);
	return (
		<div className={classes.channel}>
			<div className={classes.ChannelsMainPage_secondary}>
				{props.channel.logoUrl ? (
					<VideosImage
						imageNameWeb={channel.logoUrl}
						title="Icareus"
						onClick={() => clickhandler(channel)}
						style={{
							background: "white",
							display: "block",
							height: "169px",
							width: "300px",
							padding: "20px 50px",
						}}
					/>
				) : (
					<VideosImage
						imageName="subOrganization_default_image"
						title="Icareus"
						onClick={() => clickhandler(channel)}
						style={{
							background: "white",
							display: "block",
							height: "169px",
							width: "300px",
							borderBottom: "1px solid white",
						}}
					/>
				)}
				<div className={classes.textDescription}>
					<div className={`${classes.channelName} font-300`}>{channel.name}</div>

					<div className={`${classes.channeltext} font-200`}>
						{channel.description
							? channel.description
							: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Expedita cumque ad culpa magni, possimus error itaque ex nobis?"}
					</div>
				</div>
			</div>
			<div onClick={() => clickhandler(channel)} className={classes.channelButton}>
				<FontAwesomeIcon icon="eye" />
				View Events & Videos
			</div>
		</div>
	);
}
